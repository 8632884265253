<template>
	<div class="page container">

		<div class="page__info">
			<div class="page__info--title">
				Commission and payouts
			</div>
		</div>

		<div>
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
				v-if="formLoaded"
			>
				<checkbox-field
					name="Allow payouts to teacher (i.e. do not check if this teacher is Zeb)"
					v-model="payout.allowPayout"
				/>
				<div
					v-if="payout.allowPayout"
				>
					<input-field
						name="Bridge128's commission rate in %"
						type="text"
						rules="required"
						v-model="payout.commissionRate"
					/>
					<input-field
						name="Bridge128's minimum fee in £ (used if greater than commission rate)"
						type="text"
						rules="required"
						v-model="payout.minFee"
					/>
				</div>
				<actions :actions="getActions" />
			</validation-observer>
		</div>
	</div>
</template>

<script>
	import InputField          from '@/components/forms/InputField';
	import CheckboxField       from '@/components/forms/CheckboxField';
	import Actions             from '@/components/ui/Actions';
	import actionClick         from '@/mixins/actionClick';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import routeParams         from '@/mixins/routeParams';
	import api                 from '@/services/api';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Commission and payouts'
		},
		components: {
			CheckboxField,
			InputField,
			Actions
		},
		mixins: [actionClick, routeParams, invalidFormMessage],
		data: () => ({
			payout: {
				minFee:          0,
				allowPayout:     false,
				commissionRate:  0
			},
			formLoaded: false
		}),
		computed: {
			formatPayout () {
				return {
					minFee: 					this.payout.minFee * 100,
					allowPayout: 			this.payout.allowPayout,
					commissionRate:		(this.payout.commissionRate / 100).toFixed(3)
				};
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							type: 'submit'
						},
						{
							text: 'Cancel',
							route: this.getBreadcrumbRoute
						}
					]
				};
			},
			getBreadcrumbRoute () {
				if (!this.getUserId) {
					return false;
				}
				return `/admin/users/${this.getUserId}`;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/users',
						text: 'Users'
					},
					{
						path: `/admin/users/${this.getUserId}`,
						text: 'User'
					},
					{
						path: `/admin/users/${this.getUserId}/commission`,
						text: 'Commission'
					}
				].filter(Boolean);
			}
		},
		created () {
			this.setCommission();
		},
		methods: {
			async setCommission () {
				const payout = await api.admin.getUserPayoutInfo({
					userId: this.getUserId
				});
				if (!payout) {
					return false;
				}
				this.payout = {
					allowPayout: 			payout.allowPayout,
					minFee:           payout.minFee / 100,
					commissionRate:   (payout.commissionRate * 100).toFixed(2)
				};
				this.formLoaded = true;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				const payout = await api.admin.setUserPayoutInfo({
					payout: this.formatPayout,
					userId: this.getUserId
				});
				if (!payout) {
					return false;
				}
				await this.$router.push(this.getBreadcrumbRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'Teacher has been updated'
				});
			}
		}
	};

</script>
